import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { abortableFetch } from "abortcontroller-polyfill/dist/cjs-ponyfill";
import fetch from "node-fetch";
import { RetryLink } from "@apollo/client/link/retry";
import { getAccessToken } from "../auth/accessToken";

// See: https://github.com/apollographql/apollo-client/issues/6765#issuecomment-734469527
global.fetch = abortableFetch(fetch).fetch;

export const getApolloClient = () => {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_HASURA_HTTP_URL,
  });

  const networkLink =
    process.browser && process.env.NEXT_PUBLIC_HASURA_WS_URL
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === "OperationDefinition" &&
              definition.operation === "subscription"
            );
          },
          new WebSocketLink({
            uri: process.env.NEXT_PUBLIC_HASURA_WS_URL,
            options: {
              reconnect: true,
              timeout: 30000,
              connectionParams: async () => {
                const accessToken = getAccessToken();

                if (accessToken) {
                  return {
                    headers: {
                      Authorization: `Bearer ${accessToken}`,
                    },
                  };
                }

                return { headers: {} };
              },
            },
          }),
          httpLink
        )
      : httpLink;

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
    },
    attempts: {
      max: 10,
    },
  });

  const authLink = setContext(async (_, { headers }) => {
    const accessToken = getAccessToken();

    if (accessToken) {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }

    return { headers };
  });

  const link = from([retryLink, authLink, networkLink]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
};
