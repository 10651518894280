import { Cookies as ClientSideCookies } from "react-cookie";

const ACCESS_TOKEN_COOKIE_NAME = "access_token";
const clientSideCookies = new ClientSideCookies();

export const getAccessToken = () =>
  clientSideCookies.get(ACCESS_TOKEN_COOKIE_NAME);

export const setAccessToken = (value: string) =>
  clientSideCookies.set(ACCESS_TOKEN_COOKIE_NAME, value, {
    path: "/",
  });
export const isAuthenticated = () => !!getAccessToken();

export const clearAccessToken = () =>
  clientSideCookies.remove(ACCESS_TOKEN_COOKIE_NAME, { path: "/" });
