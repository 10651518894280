import jwt from "jsonwebtoken";
import { createContext, useEffect, useState } from "react";
import { getAccessToken } from "./accessToken";

export const AuthContext = createContext(null);

export default function AuthProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const isAuthenticated = !!accessToken;
  const tokenPayload = accessToken ? jwt.decode(accessToken) : null;

  // const refreshSession = async () => {
  //   return new Promise((resolve, reject) => {
  //     webAuth.checkSession({}, (err, authResult) => {
  //       if (err) {
  //         setAccessToken(null);
  //         clearAccessToken();
  //         reject(err);
  //       } else {
  //         setAccessToken(authResult.accessToken);
  //         setTimeout(refreshSession, 900000); // Try again in 15 minutes
  //         resolve(authResult);
  //       }
  //     });
  //   });
  // };

  const checkIsAuthenticated = async () => {
    const _accessToken = getAccessToken();
    if (_accessToken) {
      setAccessToken(_accessToken);
      try {
        // await refreshSession();
      } catch (err) {
        // noop
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    async function onLoad() {
      checkIsAuthenticated();
    }

    onLoad();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        tokenPayload,
        isAuthenticated,
        checkIsAuthenticated,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
