var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"852b55418d601fac615daf4f69e75561a6f10e9b"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
import { CaptureConsole } from "@sentry/integrations";

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://2fc2224cef4d4cb28f0ffcc70f79f123@o4504901838110720.ingest.sentry.io/4504901840142336",
  tracesSampleRate: 1.0,
  // integrations: [
  //   new CaptureConsole({
  //     levels: ["info", "error"],
  //   }),
  // ],
});
