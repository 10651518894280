import { ApolloProvider } from "@apollo/client";
import type { AppProps } from "next/app";
import { getApolloClient } from "../services/apolloClient";
import AuthProvider from "../services/auth/AuthProvider";
import "../styles/globals.scss";

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Component {...pageProps} />
      {/* <UpdatePasswordModal
        isOpen={isUpdatePasswordModalOpen}
        isDismissable={false}
        email={user?.email}
        onUpdate={() => setIsUpdatePasswordModalOpen(false)}
      /> */}
    </>
  );
}

export default function AppWithAuth(props: AppProps) {
  return (
    <AuthProvider>
      <ApolloProvider client={getApolloClient()}>
        <App {...props} />
      </ApolloProvider>
    </AuthProvider>
  );
}
